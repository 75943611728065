
import ApiService from "@/core/services/ApiService";
import { defineComponent, ref } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useBus } from "../../../../bus";

export default defineComponent({
  name: "add_info",
  components: {},
  props: {
    data: { type: Object },
  },
  setup() {
    const formData = ref({
      id: "",
      name: "",
      designation: "",
      dob: "",
      nid: "",
      mobile: "",
      email: "",
      passport: "",
      driving_license: "",
      gender: "",
      religion: "",
      present_address: "",
      present_district: "",
      permanent_address: "",
      permanent_district: "",
    });
    const formRef = ref<null | HTMLFormElement>(null);
    const addModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const update = ref<boolean>(false);
    const rules = ref({
      name: [
        {
          required: true,
          message: "Name is required",
          trigger: "change",
        },
      ],
      designation: [
        {
          required: true,
          message: "Designation is required",
          trigger: "change",
        },
      ],
      dob: [
        {
          required: true,
          message: "dob is required",
          trigger: "change",
        },
      ],
      nid: [
        {
          required: true,
          message: "NID is required",
          trigger: "change",
        },
      ],
      mobile: [
        {
          required: true,
          message: "Mobile number is required",
          trigger: "change",
        },
      ],
      email: [
        {
          required: true,
          message: "Email is required",
          trigger: "change",
        },
      ],
      //   passport: [
      //     {
      //       required: true,
      //       message: "Email is required",
      //       trigger: "change",
      //     },
      //   ],
      //   driving_license: [
      //     {
      //       required: true,
      //       message: "Email is required",
      //       trigger: "change",
      //     },
      //   ],
      gender: [
        {
          required: true,
          message: "Gender is required",
          trigger: "change",
        },
      ],
      religion: [
        {
          required: true,
          message: "Religion is required",
          trigger: "change",
        },
      ],
      //   present_address: [
      //     {
      //       required: true,
      //       message: "Present Address is required",
      //       trigger: "change",
      //     },
      //   ],
      present_district: [
        {
          required: true,
          message: "Present District is required",
          trigger: "change",
        },
      ],
      //   permanent_address:[
      //     {
      //       required: true,
      //       message: "Permanent Address is required",
      //       trigger: "change",
      //     },
      //   ],
      permanent_district: [
        {
          required: true,
          message: "Permanent District is required",
          trigger: "change",
        },
      ],
    });
    const { bus } = useBus();

    bus.on("edit-modal-data", (data) => {
      update.value = true;
      formData.value = data;
    });

    bus.on("add-modal-data", () => {
      update.value = false;
      formData.value = {
        id: "",
        name: "",
        designation: "",
        dob: "",
        nid: "",
        mobile: "",
        email: "",
        passport: "",
        driving_license: "",
        gender: "",
        religion: "",
        present_address: "",
        present_district: "",
        permanent_address: "",
        permanent_district: "",
      };
    });

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate(async (valid) => {
        if (valid) {
          loading.value = true;
          const action = update.value ? "update" : "post";
          const url = update.value
            ? "employee/newProfile" + `${formData?.value?.id}`
            : "employee/newProfile";

          await ApiService[action](url, formData.value)
            .then((response) => {
              loading.value = false;
              bus.emit("role-updated", true);
              if (response.status == 200) {
                Swal.fire({
                  text: response.data.message,
                  icon: "success",
                  buttonsStyling: false,
                  confirmButtonText: "Ok",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                }).then(() => {
                  hideModal(addModalRef.value);
                });
              } else {
                let err = "";
                for (const field of Object.keys(response.data.errors)) {
                  err += response.data.errors[field][0] + "<br>";
                }
                Swal.fire({
                  html: err,
                  icon: "error",
                  buttonsStyling: false,
                  confirmButtonText: "Close",
                  customClass: {
                    confirmButton: "btn btn-danger",
                  },
                });
              }
            })
            .catch(({ response }) => {
              loading.value = false;
              console.log(response);
            });
        }
      });
    };

    return {
      formData,
      rules,
      submit,
      formRef,
      loading,
      addModalRef,
    };
  },
  data() {
    return {
      entityTypes: [],
    };
  },
  async created() {
    //await this.getTypes();
  },
  methods: {
    async getTypes() {
      await ApiService.get("entity/types")
        .then((response) => {
          this.entityTypes = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
  },
});
